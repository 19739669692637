import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { isEmpty } from 'lodash';

import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { MainPageCard } from './components';
import { getYear } from '../../utils/functions';

const MainContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  paddingLeft: '3vw',
  marginTop: '17px',

  [theme.breakpoints.up('md')]: {
    marginTop: '66px',
  },

  [theme.breakpoints.down('md')]: {
    padding: '0 5vw',
  },
}));

const MainBox = styled(Box)({
  display: 'flex',
  gap: '20px',
  justifyContent: 'center',
  flexWrap: 'wrap',
});

const TimelineWrapper = ({ classes }) => (
  <StaticQuery
    query={graphql`
      {
        seasons: allPrismicSeasons(sort: { order: ASC, fields: data___season_end_date }) {
          nodes {
            data {
              title {
                text
              }
              image: timeline_photo {
                url
                alt
              }
              seasonTitle: season_title {
                text
              }
              birth: season_begin_date
              death: season_end_date
              seasonBeginDate: season_begin_date_ad_bc
              seasonEndDate: season_end_date_ad_bc
            }
          }
        }
        allEventLists: allPrismicEvents {
          nodes {
            data {
              eventEndDate: event_end_date
              eventYear: event_year
              yearADBC: event_year_ad_bc
              endYearADBC: event_end_year_ad_bc
            }
          }
        }
        allPeopleLists: allPrismicPeople {
          nodes {
            data {
              eventYear: birthdate
              eventEndDate: date_of_death
              yearADBC: death_date_ad_bc
              endYearADBC: death_date_ad_bc
            }
          }
        }
      }
    `}
    render={({ seasons, allEventLists, allPeopleLists }) => {
      const checkIfDataPresentInSeason = (seasonDetails) => {
        return !![...allEventLists.nodes, ...allPeopleLists.nodes].find((item) => {
          const startSeason = seasonDetails.birth
            ? seasonDetails.seasonBeginDate === 'BC'
              ? `-${parseInt(seasonDetails.birth.split('-')[0])}`
              : parseInt(seasonDetails.birth.split('-')[0])
            : 0;

          const endSeason = parseInt(seasonDetails.death)
            ? parseInt(seasonDetails.death.split('-')[0])
            : parseInt(new Date().getFullYear());

          const eventYear = parseInt(item?.data?.eventYear?.split('-')[0]);
          const eventEndDate = parseInt(item?.data?.eventEndDate?.split('-')[0]);
          return (
            startSeason < eventYear &&
            (!eventEndDate || eventEndDate < endSeason) &&
            seasonDetails.seasonBeginDate === item.data.yearADBC
          );
        });
      };

      const transistionsName = ['fade-right', 'fade-down', 'fade-left'];

      return (
        <MainContainer>
          <MainBox>
            {!isEmpty(seasons.nodes) &&
              seasons.nodes.map(({ data }, i) => (
                <MainPageCard
                  title={data.seasonTitle.text}
                  startDate={i == 0 ? 'Creation' : getYear(data.birth)}
                  endDate={getYear(data.death) ?? 'Present'}
                  imgSrc={data.image.url}
                  hasDataToLink={checkIfDataPresentInSeason(data)}
                  key={`season-item-${i}`}
                  transistionsName={transistionsName[i]}
                  index={i}
                />
              ))}
          </MainBox>
        </MainContainer>
      );
    }}
  />
);

export default TimelineWrapper;
