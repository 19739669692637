import React from 'react';
import TimelineLayout from '../modules/timeline/components/TimeLineLayout';
import TimelineWrapper from '../modules/timeline';

const Timeline = () => {
  return (
    <TimelineLayout sidebarHide>
      <TimelineWrapper />
    </TimelineLayout>
  );
};

export default Timeline;
