import { Link } from 'gatsby';
import * as React from 'react';

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

const StyledHeading = styled(Typography)({
  fontWeight: 600,
  fontSize: '22px',
  lineHeight: '26px',
  color: '#ffffff',
});

const StyledBody = styled(Typography)({
  fontWeight: 500,
  fontSize: '18px',
  lineHeight: '22px',
  color: '#ffffff',
  opacity: 0.7,
});

const StyledCardContent = styled(CardContent)({
  position: 'absolute',
  bottom: 0,
  paddingLeft: '28px',
  paddingBottom: '35px',
});

const Gradient = styled(Box)({
  background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%)',
  position: 'absolute',
  height: '100%',
  width: '100%',
});

const StyledCardMedia = styled(CardMedia)(({ bw }) => ({
  filter: bw ? 'grayscale(0)' : 'grayscale(1)',
}));

const StyledLink = styled(Link)(({ theme }) => ({
  width: 305,
  transition: ' all 0.2s ease-in-out',
  [theme.breakpoints.up('xl')]: {
    width: 425,
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
  '&:hover': {
    transform: 'scale(1.1)',
  },
}));

const MainPageCard = ({ title, imgSrc, hasDataToLink, startDate, endDate, transistionsName, index }) => {
  const [showComingSoonText, setShowComingSoonText] = React.useState(false);
  if (typeof window !== undefined) {
    return (
      <StyledLink
        to={`${hasDataToLink ? title.replace(' ', '') : '#'}`}
        onMouseEnter={() => setShowComingSoonText(true)}
        onMouseLeave={() => setShowComingSoonText(false)}
      >
        <div
          data-aos-delay={350 * index}
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-mirror="false"
          data-aos-once="true"
          data-aos={transistionsName}
        >
          <Card sx={{ position: 'relative' }}>
            <CardActionArea>
              <Gradient />
              <StyledCardMedia component="img" height="528" image={imgSrc} alt={title} bw={hasDataToLink} />
              {!hasDataToLink && showComingSoonText && (
                <>
                  <h2
                    className="fade-in"
                    style={{ position: 'absolute', top: '50%', textAlign: 'center', width: '100%' }}
                  >
                    Coming Soon
                  </h2>
                </>
              )}
              <StyledCardContent>
                <StyledHeading gutterBottom variant="h5" component="div">
                  {title}
                </StyledHeading>
                <StyledBody variant="body2" color="text.secondary">
                  {startDate} - {endDate}
                </StyledBody>
              </StyledCardContent>
            </CardActionArea>
          </Card>
        </div>
      </StyledLink>
    );
  }
  return null;
};

export default MainPageCard;
